body,
html {
    font-family: Helvetica, sans-serif;
    color: #4A4A4A;
    height: 100%;
    margin: 0;
}

.brand-logo {
    color: #3aa755e3;
}

.login_wrapper {
    padding: 30px 0;
    margin: 0px auto;
    width: 70%;
}

.shopping-cart-icon {
    margin-right: 40px;
}

.shopping_cart_badge {
    font-weight: 400;
    top: -10px;
    right: -20px;
    transform: scale(0.8);
}

.subheader-container {
    width: 100%;
    height: 50px;
    position: relative;
    margin: 10px;
}

.sort-products-select {
    margin: 10px;
}

.form_input {
    margin: 0 0 25px 0;
    font-size: 18px;
    width: 100%;
    border: 1px solid #f5f5f5;
    outline: none;
    padding: 10px 0;
}

.error {
    color: red;
}

.amount {
    font-weight: bold;
}

.amount-total {
    border-top: 1px solid #f5f5f5;
    font-size: 1.3em;
}

.section-title {
    border-bottom: 1px solid #f5f5f5;
    font-size: 1.3em;
    font-weight: bold;
    color: #666;
    margin-bottom: 10px;
    margin-top: 10px;
}